// eslint-disable-next-line import/no-cycle
import {
  MEMBERS_SEARCH,
  MEMBERS_SEARCH_SUCCESS,
  MEMBERS_SEARCH_ERROR,
  GET_MEMBER,
  GET_MEMBER_SUCCESS,
  GET_MEMBER_ERROR,
  SAVE_MEMBER,
  SAVE_MEMBER_ERROR,
  CREATE_MEMBER,
  CREATE_MEMBER_ERROR,
} from '../actions';

import memberService from '../../services/memberService';
import { adminRoot } from '../../constants/defaultValues';

export const searchMembersSuccess = (members) => ({
  type: MEMBERS_SEARCH_SUCCESS,
  payload: members,
});

export const searchMembersError = (message) => ({
  type: MEMBERS_SEARCH_ERROR,
  payload: { message },
});

export const searchMembers = (searchData, history) => {
  return async (dispatch) => {
    dispatch({ type: MEMBERS_SEARCH, payload: { searchData, history } });
    const members = await memberService.search(searchData);
    if (members && members.data) {
      dispatch(searchMembersSuccess(members.data));
    } else if (members && members.message) {
      dispatch(searchMembersError(members.message));
    } else {
      dispatch(searchMembersError('Something went wrong'));
    }
  };
};

export const getMemberSuccess = (member) => ({
  type: GET_MEMBER_SUCCESS,
  payload: member,
});

export const getMemberError = (message) => ({
  type: GET_MEMBER_ERROR,
  payload: { message },
});

export const getMember = (memberId) => {
  return async (dispatch) => {
    dispatch({ type: GET_MEMBER, payload: { memberId } });
    const member = await memberService.get(memberId);
    if (member && member.data) {
      dispatch(getMemberSuccess(member.data));
    } else if (member && member.message) {
      dispatch(getMemberError(member.message));
    } else {
      dispatch(getMemberError('Something went wrong'));
    }
  };
};

export const saveMemberError = (message) => ({
  type: SAVE_MEMBER_ERROR,
  payload: { message },
});

export const saveMember = (memberId, memberData, history) => {
  return async (dispatch) => {
    dispatch({ type: SAVE_MEMBER, payload: { memberId, memberData } });
    const member = await memberService.update(memberId, memberData);
    if (member && member.data && member.data.success) {
      history.push(`${adminRoot}/members/${memberId}`);
    } else if (member && member.data && member.data.messages) {
      dispatch(saveMemberError(member.data.messages));
    } else {
      dispatch(saveMemberError('Something went wrong'));
    }
  };
};

export const createMemberError = (message) => ({
  type: CREATE_MEMBER_ERROR,
  payload: { message },
});

export const createMember = (memberData, history) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_MEMBER, payload: { memberData } });
    const member = await memberService.create(memberData);
    if (member && member.data && member.data.success) {
      history.push(`${adminRoot}/members/${member.data.member.id}`);
    } else if (member && member.data && member.data.messages) {
      dispatch(createMemberError(member.data.messages));
    } else {
      dispatch(createMemberError('Something went wrong'));
    }
  };
};
