import { OPEN_DOOR, OPEN_DOOR_SUCCESS, OPEN_DOOR_ERROR } from '../actions';

const INIT_STATE = {
  checkinData: { members: [], count: 0 },
  loading: false,
  error: null,
  message: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_DOOR:
      return { ...state, message: null, error: null };
    case OPEN_DOOR_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        error: null,
      };
    case OPEN_DOOR_ERROR:
      return {
        ...state,
        loading: false,
        message: null,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
