// eslint-disable-next-line import/no-cycle
import {
  GET_BILLING_INFORMATION,
  GET_BILLING_INFORMATION_ERROR,
  GET_BILLING_INFORMATION_SUCCESS,
  BILLING_INFORMATIONS_SEARCH,
  BILLING_INFORMATIONS_SEARCH_ERROR,
  BILLING_INFORMATIONS_SEARCH_SUCCESS,
} from '../actions';
import billingInformationService from '../../services/billingInformationService';

export const searchBillingInformationsSuccess = (billingInformations) => ({
  type: BILLING_INFORMATIONS_SEARCH_SUCCESS,
  payload: billingInformations,
});

export const searchBillingInformationsError = (message) => ({
  type: BILLING_INFORMATIONS_SEARCH_ERROR,
  payload: { message },
});

export const searchBillingInformations = (searchData, history) => {
  return async (dispatch) => {
    dispatch({
      type: BILLING_INFORMATIONS_SEARCH,
      payload: { searchData, history },
    });
    const billingInformations = await billingInformationService.search(
      searchData
    );
    if (billingInformations && billingInformations.data) {
      dispatch(searchBillingInformationsSuccess(billingInformations.data));
    } else if (billingInformations && billingInformations.message) {
      dispatch(searchBillingInformationsError(billingInformations.message));
    } else {
      dispatch(searchBillingInformationsError('Something went wrong'));
    }
  };
};

export const getBillingInformationSuccess = (billingInformation) => ({
  type: GET_BILLING_INFORMATION_SUCCESS,
  payload: billingInformation,
});

export const getBillingInformationError = (message) => ({
  type: GET_BILLING_INFORMATION_ERROR,
  payload: { message },
});

export const getBillingInformation = (memberId, membershipId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_BILLING_INFORMATION,
      payload: { memberId, membershipId },
    });
    const billingInformation = await billingInformationService.get(
      memberId,
      membershipId
    );
    if (billingInformation && billingInformation.data) {
      dispatch(getBillingInformationSuccess(billingInformation.data));
    } else if (billingInformation && billingInformation.message) {
      dispatch(getBillingInformationError(billingInformation.message));
    } else {
      dispatch(getBillingInformationError('Something went wrong'));
    }
  };
};
