import {
  MEMBER_MESSAGES_LIST,
  MEMBER_MESSAGES_LIST_SUCCESS,
  MEMBER_MESSAGES_LIST_ERROR,
} from '../actions';

const INIT_STATE = {
  memberMessagesData: { memberMessages: [], count: 0 },
  memberMessage: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MEMBER_MESSAGES_LIST:
      return { ...state, loading: true, error: '' };
    case MEMBER_MESSAGES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        memberMessagesData: action.payload,
        error: '',
      };
    case MEMBER_MESSAGES_LIST_ERROR:
      return {
        ...state,
        loading: false,
        memberMessagesData: INIT_STATE.memberMessagesData,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
