// eslint-disable-next-line import/no-cycle
import {
  MEMBERSHIPS_SEARCH,
  MEMBERSHIPS_SEARCH_SUCCESS,
  MEMBERSHIPS_SEARCH_ERROR,
  GET_MEMBERSHIP,
  GET_MEMBERSHIP_SUCCESS,
  GET_MEMBERSHIP_ERROR,
  SAVE_MEMBERSHIP,
  SAVE_MEMBERSHIP_ERROR,
  CREATE_MEMBERSHIP,
  CREATE_MEMBERSHIP_ERROR,
} from '../actions';

import membershipService from '../../services/membershipService';
import { adminRoot } from '../../constants/defaultValues';

export const searchMembershipsSuccess = (memberships) => ({
  type: MEMBERSHIPS_SEARCH_SUCCESS,
  payload: memberships,
});

export const searchMembershipsError = (message) => ({
  type: MEMBERSHIPS_SEARCH_ERROR,
  payload: { message },
});

export const searchMemberships = (searchData, history) => {
  return async (dispatch) => {
    dispatch({ type: MEMBERSHIPS_SEARCH, payload: { searchData, history } });
    const memberships = await membershipService.search(searchData);
    if (memberships && memberships.data) {
      dispatch(searchMembershipsSuccess(memberships.data));
    } else if (memberships && memberships.message) {
      dispatch(searchMembershipsError(memberships.message));
    } else {
      dispatch(searchMembershipsError('Something went wrong'));
    }
  };
};

export const getMembershipSuccess = (membership) => ({
  type: GET_MEMBERSHIP_SUCCESS,
  payload: membership,
});

export const getMembershipError = (message) => ({
  type: GET_MEMBERSHIP_ERROR,
  payload: { message },
});

export const getMembership = (memberId, membershipId) => {
  return async (dispatch) => {
    dispatch({ type: GET_MEMBERSHIP, payload: { memberId, membershipId } });
    const membership = await membershipService.get(memberId, membershipId);
    if (membership && membership.data) {
      dispatch(getMembershipSuccess(membership.data));
    } else if (membership && membership.message) {
      dispatch(getMembershipError(membership.message));
    } else {
      dispatch(getMembershipError('Something went wrong'));
    }
  };
};

export const saveMembershipError = (message) => ({
  type: SAVE_MEMBERSHIP_ERROR,
  payload: { message },
});

export const saveMembership = (
  memberId,
  membershipId,
  membershipData,
  history
) => {
  return async (dispatch) => {
    dispatch({
      type: SAVE_MEMBERSHIP,
      payload: { membershipId, membershipData },
    });
    const membership = await membershipService.update(
      memberId,
      membershipId,
      membershipData
    );
    if (membership && membership.data && membership.data.success) {
      history.push(
        `${adminRoot}/members/${memberId}/memberships/${membershipId}`
      );
    } else if (membership && membership.data && membership.data.messages) {
      dispatch(saveMembershipError(membership.data.messages));
    } else {
      dispatch(saveMembershipError('Something went wrong'));
    }
  };
};

export const createMembershipError = (message) => ({
  type: CREATE_MEMBERSHIP_ERROR,
  payload: { message },
});

export const createMembership = (memberId, membershipData, history) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_MEMBERSHIP, payload: { membershipData } });
    const membership = await membershipService.create(memberId, membershipData);
    if (membership && membership.data && membership.data.success) {
      history.push(
        `${adminRoot}/members/${memberId}/memberships/${membership.data.membership.id}`
      );
    } else if (membership && membership.data && membership.data.messages) {
      dispatch(getMembershipError(membership.data.messages));
    } else {
      dispatch(getMembershipError('Something went wrong'));
    }
  };
};
