import moment from 'moment';
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
  cachePrefix,
} from '../constants/defaultValues';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem(`${cachePrefix}_direction`)) {
      const localValue = localStorage.getItem(`${cachePrefix}_direction`);
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem(`${cachePrefix}_direction`, direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(`${cachePrefix}_${themeColorStorageKey}`)) {
      currentColor = localStorage.getItem(
        `${cachePrefix}_${themeColorStorageKey}`
      );
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(`${cachePrefix}_${themeColorStorageKey}`, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(`${cachePrefix}_${themeRadiusStorageKey}`)) {
      currentRadius = localStorage.getItem(
        `${cachePrefix}_${themeRadiusStorageKey}`
      );
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(`${cachePrefix}_${themeRadiusStorageKey}`, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem(`${cachePrefix}_currentLanguage`) &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem(`${cachePrefix}_currentLanguage`)
      ).length > 0
        ? localStorage.getItem(`${cachePrefix}_currentLanguage`)
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem(`${cachePrefix}_currentLanguage`, locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getToken = () => {
  let token = null;
  try {
    token =
      localStorage.getItem(`${cachePrefix}_token`) != null
        ? JSON.parse(localStorage.getItem(`${cachePrefix}_token`))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getToken -> error', error);
    token = null;
  }
  return token;
};

export const setToken = (token, bname) => {
  try {
    if (token) {
      localStorage.setItem(
        `${cachePrefix}_token`,
        JSON.stringify({ token, bname })
      );
      localStorage.setItem(
        `${cachePrefix}_refreshTime`,
        JSON.stringify(moment().unix())
      );
    } else {
      localStorage.removeItem(`${cachePrefix}_token`);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setToken -> error', error);
  }
};

export const deleteToken = () => {
  try {
    localStorage.removeItem(`${cachePrefix}_token`);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : deleteToken -> error', error);
  }
};

export const getTokenRefreshTime = () => {
  let refreshTime = null;
  try {
    refreshTime =
      getToken() && localStorage.getItem(`${cachePrefix}_refreshTime`) != null
        ? JSON.parse(localStorage.getItem(`${cachePrefix}_refreshTime`))
        : null;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js  : getTokenRefreshTime -> error',
      error
    );
    refreshTime = null;
  }
  return refreshTime;
};

export const isEmpty = (object) => {
  if (object === null) return true;
  return JSON.stringify(object) === '{}';
};

export const fetchAllAttributes = (_attributes) => {
  // eslint-disable-next-line
  let allAttributes = [];
  Object.keys(_attributes).forEach((key) => {
    _attributes[key].forEach((attributeGroup) => {
      if (attributeGroup && !attributeGroup.sub_group)
        allAttributes.push(attributeGroup);
      else {
        attributeGroup.sub_attributes.forEach((subAttribute) => {
          allAttributes.push(subAttribute);
        });
      }
    });
  });

  return allAttributes;
};
