import { NOTES_LIST, NOTES_LIST_SUCCESS, NOTES_LIST_ERROR } from '../actions';

const INIT_STATE = {
  notesData: { notes: [], count: 0 },
  note: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case NOTES_LIST:
      return { ...state, loading: true, error: '' };
    case NOTES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        notesData: action.payload,
        error: '',
      };
    case NOTES_LIST_ERROR:
      return {
        ...state,
        loading: false,
        notesData: INIT_STATE.notesData,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
