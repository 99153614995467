// eslint-disable-next-line import/no-cycle
import { OPEN_DOOR, OPEN_DOOR_SUCCESS, OPEN_DOOR_ERROR } from '../actions';
import checkinService from '../../services/checkinService';

export const openDoor = () => {
  return async (dispatch) => {
    dispatch({ type: OPEN_DOOR, payload: {} });
    const response = await checkinService.openDoor();
    if (response && response.data) {
      dispatch({
        type: OPEN_DOOR_SUCCESS,
        payload: {
          message: 'Door opened successfully',
        },
      });
    } else {
      dispatch({
        type: OPEN_DOOR_ERROR,
        payload: {
          message: 'Something went wrong',
        },
      });
    }
  };
};

export const checkinMember = () => {};
