import axios from 'axios';
import { getToken, setToken } from '../helpers/Utils';
import commonService from './commonService';
import { urls } from '../constants/urls';

async function login(userData) {
  try {
    const result = await axios.get(`${urls.login}/login_user`, {
      headers: {
        'Business-Name': userData.businessName,
        Login: userData.username,
        Password: userData.password,
      },
    });
    setToken(result.data.access_token, userData.businessName);
    return getToken().token;
  } catch (error) {
    console.log('>>>>: src/services/authService.js  : login -> error', error);
  }
  return null;
}

async function getUser() {
  try {
    const accessToken = await commonService.refreshAccessToken();
    return await axios.get(`${urls.users}/detail`, {
      headers: {
        'Business-Name': accessToken.bname,
        'Access-Token': accessToken.token,
      },
    });
  } catch (error) {
    console.log('>>>>: src/services/authService.js  : user -> error', error);
  }
  return null;
}

export default {
  login,
  getUser,
};
