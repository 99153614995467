import moment from 'moment';
import axios from 'axios';
import { getTokenRefreshTime, getToken, setToken } from '../helpers/Utils';
import { tokenExpiry } from '../constants/defaultValues';
import { urls } from '../constants/urls';

function shouldRefreshToken() {
  return moment().unix() - getTokenRefreshTime() > tokenExpiry;
}

async function refreshAccessToken() {
  if (shouldRefreshToken()) {
    const { bname, token } = getToken();
    try {
      const result = await axios.get(`${urls.login}/refresh_token`, {
        headers: {
          'Business-Name': bname,
          'Access-Token': token,
        },
      });
      setToken(result.data.token);
    } catch (error) {
      console.log(
        '>>>>: src/services/commonService.js  : refreshAccessToken -> error',
        error
      );
    }
  }
  return getToken();
}

async function getOptions(url) {
  try {
    const accessToken = await refreshAccessToken();
    return await axios.get(url, {
      headers: {
        'Business-Name': accessToken.bname,
        'Access-Token': accessToken.token,
      },
      params: { status: 'all' },
    });
  } catch (error) {
    console.log(
      '>>>>: src/services/commonService.js  : getOptions -> error',
      error
    );
  }
  return [];
}

export default {
  refreshAccessToken,
  getOptions,
};
