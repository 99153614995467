/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

/* MEMBERS */
export const MEMBERS_SEARCH = 'MEMBERS_SEARCH';
export const MEMBERS_SEARCH_SUCCESS = 'MEMBERS_SEARCH_SUCCESS';
export const MEMBERS_SEARCH_ERROR = 'MEMBERS_SEARCH_ERROR';
export const GET_MEMBER = 'GET_MEMBER';
export const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
export const GET_MEMBER_ERROR = 'GET_MEMBER_ERROR';
export const SAVE_MEMBER = 'SAVE_MEMBER';
export const SAVE_MEMBER_ERROR = 'SAVE_MEMBER_ERROR';
export const CREATE_MEMBER = 'CREATE_MEMBER';
export const CREATE_MEMBER_ERROR = 'CREATE_MEMBER_ERROR';
export const GET_MEMBER_BALANCES = 'GET_MEMBER_BALANCES';
export const GET_MEMBER_BALANCES_SUCCESS = 'GET_MEMBER_BALANCES_SUCCESS';
export const GET_MEMBER_BALANCES_ERROR = 'GET_MEMBER_BALANCES_ERROR';

/* MEMBERSHIPS */
export const MEMBERSHIPS_SEARCH = 'MEMBERSHIPS_SEARCH';
export const MEMBERSHIPS_SEARCH_SUCCESS = 'MEMBERSHIPS_SEARCH_SUCCESS';
export const MEMBERSHIPS_SEARCH_ERROR = 'MEMBERSHIPS_SEARCH_ERROR';
export const GET_MEMBERSHIP = 'GET_MEMBERSHIP';
export const GET_MEMBERSHIP_SUCCESS = 'GET_MEMBERSHIP_SUCCESS';
export const GET_MEMBERSHIP_ERROR = 'GET_MEMBERSHIP_ERROR';
export const SAVE_MEMBERSHIP = 'SAVE_MEMBERSHIP';
export const SAVE_MEMBERSHIP_ERROR = 'SAVE_MEMBERSHIP_ERROR';
export const CREATE_MEMBERSHIP = 'CREATE_MEMBERSHIP';
export const CREATE_MEMBERSHIP_ERROR = 'CREATE_MEMBERSHIP_ERROR';

/* ACCOUNTS */
export const ACCOUNTS_SEARCH = 'ACCOUNTS_SEARCH';
export const ACCOUNTS_SEARCH_SUCCESS = 'ACCOUNTS_SEARCH_SUCCESS';
export const ACCOUNTS_SEARCH_ERROR = 'ACCOUNTS_SEARCH_ERROR';
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

/* BILLING_INFORMATION */
export const BILLING_INFORMATIONS_SEARCH = 'BILLING_INFORMATIONS_SEARCH';
export const BILLING_INFORMATIONS_SEARCH_SUCCESS =
  'BILLING_INFORMATIONS_SEARCH_SUCCESS';
export const BILLING_INFORMATIONS_SEARCH_ERROR =
  'BILLING_INFORMATIONS_SEARCH_ERROR';
export const GET_BILLING_INFORMATION = 'GET_BILLING_INFORMATION';
export const GET_BILLING_INFORMATION_SUCCESS =
  'GET_BILLING_INFORMATION_SUCCESS';
export const GET_BILLING_INFORMATION_ERROR = 'GET_BILLING_INFORMATION_ERROR';

/* NOTES */
export const NOTES_LIST = 'NOTES_LIST';
export const NOTES_LIST_SUCCESS = 'NOTES_LIST_SUCCESS';
export const NOTES_LIST_ERROR = 'NOTES_LIST_ERROR';

/* MEMBER_MESSAGES */
export const MEMBER_MESSAGES_LIST = 'MEMBER_MESSAGES_LIST';
export const MEMBER_MESSAGES_LIST_SUCCESS = 'MEMBER_MESSAGES_LIST_SUCCESS';
export const MEMBER_MESSAGES_LIST_ERROR = 'MEMBER_MESSAGES_LIST_ERROR';

/* ADMIN_MESSAGES */
export const ADMIN_MESSAGES_LIST = 'ADMIN_MESSAGES_LIST';
export const ADMIN_MESSAGES_LIST_SUCCESS = 'ADMIN_MESSAGES_LIST_SUCCESS';
export const ADMIN_MESSAGES_LIST_ERROR = 'ADMIN_MESSAGES_LIST_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* CHECKING */
export const OPEN_DOOR = 'OPEN_DOOR';
export const OPEN_DOOR_SUCCESS = 'OPEN_DOOR_SUCCESS';
export const OPEN_DOOR_ERROR = 'OPEN_DOOR_ERROR';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './members/actions';
export * from './accounts/actions';
export * from './memberships/actions';
export * from './billingInformations/actions';
export * from './checkin/actions';
