import {
  BILLING_INFORMATIONS_SEARCH,
  BILLING_INFORMATIONS_SEARCH_SUCCESS,
  BILLING_INFORMATIONS_SEARCH_ERROR,
  GET_BILLING_INFORMATION,
  GET_BILLING_INFORMATION_SUCCESS,
  GET_BILLING_INFORMATION_ERROR,
} from '../actions';

const INIT_STATE = {
  billingInformationsData: { billingInformations: [], count: 0 },
  balances: [],
  billingInformation: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case BILLING_INFORMATIONS_SEARCH:
      return { ...state, loading: true, error: '' };
    case BILLING_INFORMATIONS_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        billingInformationsData: action.payload,
        error: '',
      };
    case BILLING_INFORMATIONS_SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        billingInformationsData: INIT_STATE.billingInformationsData,
        error: action.payload.message,
      };
    case GET_BILLING_INFORMATION:
      return { ...state, loading: true, error: '' };
    case GET_BILLING_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        billingInformation: action.payload,
        error: '',
      };
    case GET_BILLING_INFORMATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
