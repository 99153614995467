import axios from 'axios';
import commonService from './commonService';
import { urls } from '../constants/urls';

async function search(searchData) {
  try {
    const accessToken = await commonService.refreshAccessToken();
    return await axios.get(`${urls.members}/members`, {
      params: searchData,
      headers: {
        'Business-Name': accessToken.bname,
        'Access-Token': accessToken.token,
      },
    });
  } catch (error) {
    console.log(
      '>>>>: src/services/memberService.js  : search -> error',
      error
    );
  }
  return null;
}

async function get(memberId) {
  try {
    const accessToken = await commonService.refreshAccessToken();
    return await axios.get(`${urls.members}/members/${memberId}`, {
      headers: {
        'Business-Name': accessToken.bname,
        'Access-Token': accessToken.token,
      },
    });
  } catch (error) {
    console.log('>>>>: src/services/memberService.js  : get -> error', error);
  }
  return null;
}

async function update(memberId, memberData) {
  try {
    const accessToken = await commonService.refreshAccessToken();
    return await axios.post(`${urls.members}/members/${memberId}`, memberData, {
      headers: {
        'Business-Name': accessToken.bname,
        'Access-Token': accessToken.token,
      },
    });
  } catch (error) {
    console.log(
      '>>>>: src/services/memberService.js  : update -> error',
      error
    );
  }
  return null;
}

async function create(memberData) {
  try {
    const accessToken = await commonService.refreshAccessToken();
    return await axios.post(`${urls.members}/members/`, memberData, {
      headers: {
        'Business-Name': accessToken.bname,
        'Access-Token': accessToken.token,
      },
    });
  } catch (error) {
    console.log(
      '>>>>: src/services/memberService.js  : create -> error',
      error
    );
  }
  return null;
}

async function getNotes(memberId) {
  try {
    const accessToken = await commonService.refreshAccessToken();
    return await axios.get(`${urls.members}/members/${memberId}/notes`, {
      headers: {
        'Business-Name': accessToken.bname,
        'Access-Token': accessToken.token,
      },
    });
  } catch (error) {
    console.log(
      '>>>>: src/services/memberService.js  : getNotes -> error',
      error
    );
  }
  return null;
}

async function getBalances(memberId) {
  try {
    const accessToken = await commonService.refreshAccessToken();
    return await axios.get(
      `${urls.members}/members/${memberId}/accounts/balances`,
      {
        headers: {
          'Business-Name': accessToken.bname,
          'Access-Token': accessToken.token,
        },
      }
    );
  } catch (error) {
    console.log(
      '>>>>: src/services/memberService.js  : getBalances -> error',
      error
    );
  }
  return null;
}

async function getMemberMessages(memberId) {
  try {
    const accessToken = await commonService.refreshAccessToken();
    return await axios.get(
      `${urls.members}/members/${memberId}/member_messages`,
      {
        headers: {
          'Business-Name': accessToken.bname,
          'Access-Token': accessToken.token,
        },
      }
    );
  } catch (error) {
    console.log(
      '>>>>: src/services/memberService.js  : getMemberMessages -> error',
      error
    );
  }
  return null;
}

export default {
  search,
  get,
  update,
  create,
  getNotes,
  getBalances,
  getMemberMessages,
};
