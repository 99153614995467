import {
  ADMIN_MESSAGES_LIST,
  ADMIN_MESSAGES_LIST_SUCCESS,
  ADMIN_MESSAGES_LIST_ERROR,
} from '../actions';

const INIT_STATE = {
  adminMessagesData: { messages: [], count: 0 },
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADMIN_MESSAGES_LIST:
      return { ...state, loading: true, error: '' };
    case ADMIN_MESSAGES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        adminMessagesData: action.payload,
        error: '',
      };
    case ADMIN_MESSAGES_LIST_ERROR:
      return {
        ...state,
        loading: false,
        adminMessagesData: INIT_STATE.adminMessagesData,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
