export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const urls = {
  login: `${apiBaseUrl}/login/user_api`,
  members: `${apiBaseUrl}/members/user_api`,
  users: `${apiBaseUrl}/users`,
  checkin: `${apiBaseUrl}/checkin/user_api`,
  sites: `${apiBaseUrl}/sites/`,
  membershipTypes: `${apiBaseUrl}/membership_types/`,
  statuses: `${apiBaseUrl}/statuses/`,
  paymentTypes: `${apiBaseUrl}/payment_types/`,
  activities: `${apiBaseUrl}/activities/`,
  colors: `${apiBaseUrl}/colors/`,
  adminMessages: `${apiBaseUrl}/messages/`,
};
