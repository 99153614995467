import axios from 'axios';
import commonService from './commonService';
import { urls } from '../constants/urls';

async function openDoor() {
  try {
    const accessToken = await commonService.refreshAccessToken();
    return await axios.get(`${urls.checkin}/checkin/open_door`, {
      headers: {
        'Business-Name': accessToken.bname,
        'Access-Token': accessToken.token,
      },
    });
  } catch (error) {
    console.log(
      '>>>>: src/services/checkinService.js  : openDoor -> error',
      error
    );
  }
  return null;
}

export default {
  openDoor,
};
