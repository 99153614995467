// eslint-disable-next-line import/no-cycle
import {
  GET_MEMBER_BALANCES,
  GET_MEMBER_BALANCES_ERROR,
  GET_MEMBER_BALANCES_SUCCESS,
  ACCOUNTS_SEARCH,
  ACCOUNTS_SEARCH_ERROR,
  ACCOUNTS_SEARCH_SUCCESS,
  GET_ACCOUNT,
  GET_ACCOUNT_ERROR,
  GET_ACCOUNT_SUCCESS,
} from '../actions';

import accountService from '../../services/accountService';
import memberService from '../../services/memberService';

export const searchAccountsSuccess = (accounts) => ({
  type: ACCOUNTS_SEARCH_SUCCESS,
  payload: accounts,
});

export const searchAccountsError = (message) => ({
  type: ACCOUNTS_SEARCH_ERROR,
  payload: { message },
});

export const searchAccounts = (searchData, history) => {
  return async (dispatch) => {
    dispatch({ type: ACCOUNTS_SEARCH, payload: { searchData, history } });
    const accounts = await accountService.search(searchData);
    if (accounts && accounts.data) {
      dispatch(searchAccountsSuccess(accounts.data));
    } else if (accounts && accounts.message) {
      dispatch(searchAccountsError(accounts.message));
    } else {
      dispatch(searchAccountsError('Something went wrong'));
    }
  };
};

export const getAccountSuccess = (account) => ({
  type: GET_ACCOUNT_SUCCESS,
  payload: account,
});

export const getAccountError = (message) => ({
  type: GET_ACCOUNT_ERROR,
  payload: { message },
});

export const getAccount = (memberId, accountId) => {
  return async (dispatch) => {
    dispatch({ type: GET_ACCOUNT, payload: { memberId, accountId } });
    const account = await accountService.get(memberId, accountId);
    if (account && account.data) {
      dispatch(getAccountSuccess(account.data));
    } else if (account && account.message) {
      dispatch(getAccountError(account.message));
    } else {
      dispatch(getAccountError('Something went wrong'));
    }
  };
};

export const getMemberBalancesSuccess = (balances) => ({
  type: GET_MEMBER_BALANCES_SUCCESS,
  payload: balances,
});

export const getMemberBalancesError = (message) => ({
  type: GET_MEMBER_BALANCES_ERROR,
  payload: { message },
});

export const getMemberBalances = (memberId) => {
  return async (dispatch) => {
    dispatch({ type: GET_MEMBER_BALANCES, payload: { memberId } });
    const balances = await memberService.getBalances(memberId);
    if (balances && balances.data) {
      dispatch(getMemberBalancesSuccess(balances.data));
    } else if (balances && balances.message) {
      dispatch(getMemberBalancesError(balances.message));
    } else {
      dispatch(getMemberBalancesError('Something went wrong'));
    }
  };
};
