import axios from 'axios';
import commonService from './commonService';
import { urls } from '../constants/urls';

async function search(searchData) {
  try {
    const accessToken = await commonService.refreshAccessToken();
    return await axios.get(
      `${urls.members}/members/${searchData.memberId}/billing_informations`,
      {
        params: searchData,
        headers: {
          'Business-Name': accessToken.bname,
          'Access-Token': accessToken.token,
        },
      }
    );
  } catch (error) {
    console.log(
      '>>>>: src/services/billingInformationService.js  : search -> error',
      error
    );
  }
  return null;
}

async function get(memberId, membershipId) {
  try {
    const accessToken = await commonService.refreshAccessToken();
    return await axios.get(
      `${urls.members}/members/${memberId}/billing_informations/${membershipId}`,
      {
        headers: {
          'Business-Name': accessToken.bname,
          'Access-Token': accessToken.token,
        },
      }
    );
  } catch (error) {
    console.log(
      '>>>>: src/services/billingInformationService.js  : get -> error',
      error
    );
  }
  return null;
}

export default {
  search,
  get,
};
