import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import members from './members/reducer';
import notes from './notes/reducer';
import memberMessages from './memberMessages/reducer';
import accounts from './accounts/reducer';
import memberships from './memberships/reducer';
import billingInformations from './billingInformations/reducer';
import checkin from './checkin/reducer';
import adminMessages from './adminMessages/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  membersData: members,
  accountsData: accounts,
  notesData: notes,
  memberMessagesData: memberMessages,
  adminMessagesData: adminMessages,
  membershipsData: memberships,
  billingInformationsData: billingInformations,
  checkinData: checkin,
});

export default reducers;
