import {
  GET_MEMBER_BALANCES,
  GET_MEMBER_BALANCES_SUCCESS,
  GET_MEMBER_BALANCES_ERROR,
  ACCOUNTS_SEARCH,
  ACCOUNTS_SEARCH_SUCCESS,
  ACCOUNTS_SEARCH_ERROR,
  GET_ACCOUNT,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_ERROR,
} from '../actions';

const INIT_STATE = {
  accountsData: { accounts: [], count: 0 },
  balances: [],
  account: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACCOUNTS_SEARCH:
      return { ...state, loading: true, error: '' };
    case ACCOUNTS_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        accountsData: action.payload,
        error: '',
      };
    case ACCOUNTS_SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        accountsData: INIT_STATE.accountsData,
        error: action.payload.message,
      };
    case GET_ACCOUNT:
      return { ...state, loading: true, error: '' };
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        account: action.payload,
        error: '',
      };
    case GET_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case GET_MEMBER_BALANCES:
      return { ...state, loading: true, error: '' };
    case GET_MEMBER_BALANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        balances: action.payload,
        error: '',
      };
    case GET_MEMBER_BALANCES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
