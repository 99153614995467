import axios from 'axios';
import commonService from './commonService';
import { urls } from '../constants/urls';

async function search(searchData) {
  try {
    const accessToken = await commonService.refreshAccessToken();
    return await axios.get(
      `${urls.members}/members/${searchData.memberId}/memberships`,
      {
        params: searchData,
        headers: {
          'Business-Name': accessToken.bname,
          'Access-Token': accessToken.token,
        },
      }
    );
  } catch (error) {
    console.log(
      '>>>>: src/services/membershipService.js  : search -> error',
      error
    );
  }
  return null;
}

async function get(memberId, membershipId) {
  try {
    const accessToken = await commonService.refreshAccessToken();
    return await axios.get(
      `${urls.members}/members/${memberId}/memberships/${membershipId}`,
      {
        headers: {
          'Business-Name': accessToken.bname,
          'Access-Token': accessToken.token,
        },
      }
    );
  } catch (error) {
    console.log(
      '>>>>: src/services/membershipService.js : get -> error',
      error
    );
  }
  return null;
}

async function update(memberId, membershipId, membershipData) {
  try {
    const accessToken = await commonService.refreshAccessToken();
    return await axios.post(
      `${urls.members}/members/${memberId}/memberships/${membershipId}`,
      membershipData,
      {
        headers: {
          'Business-Name': accessToken.bname,
          'Access-Token': accessToken.token,
        },
      }
    );
  } catch (error) {
    console.log(
      '>>>>: src/services/membershipService.js : update -> error',
      error
    );
  }
  return null;
}

async function create(memberId, membershipData) {
  try {
    const accessToken = await commonService.refreshAccessToken();
    return await axios.post(
      `${urls.members}/members/${memberId}/memberships`,
      membershipData,
      {
        headers: {
          'Business-Name': accessToken.bname,
          'Access-Token': accessToken.token,
        },
      }
    );
  } catch (error) {
    console.log(
      '>>>>: src/services/membershipService.js : create -> error',
      error
    );
  }
  return null;
}

export default {
  search,
  get,
  update,
  create,
};
