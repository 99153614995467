import {
  MEMBERS_SEARCH,
  MEMBERS_SEARCH_SUCCESS,
  MEMBERS_SEARCH_ERROR,
  GET_MEMBER,
  GET_MEMBER_SUCCESS,
  GET_MEMBER_ERROR,
  SAVE_MEMBER,
  SAVE_MEMBER_ERROR,
  CREATE_MEMBER,
  CREATE_MEMBER_ERROR,
} from '../actions';

const INIT_STATE = {
  membersData: { members: [], count: 0 },
  member: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MEMBERS_SEARCH:
      return { ...state, loading: true, error: '' };
    case MEMBERS_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        membersData: action.payload,
        error: '',
      };
    case MEMBERS_SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        membersData: INIT_STATE.membersData,
        error: action.payload.message,
      };
    case GET_MEMBER:
      return { ...state, loading: true, error: '' };
    case GET_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        member: action.payload,
        error: '',
      };
    case GET_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case SAVE_MEMBER:
      return { ...state, loading: true, error: '' };
    case SAVE_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CREATE_MEMBER:
      return { ...state, loading: true, error: '' };
    case CREATE_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
