import {
  MEMBERSHIPS_SEARCH,
  MEMBERSHIPS_SEARCH_SUCCESS,
  MEMBERSHIPS_SEARCH_ERROR,
  GET_MEMBERSHIP,
  GET_MEMBERSHIP_SUCCESS,
  GET_MEMBERSHIP_ERROR,
} from '../actions';

const INIT_STATE = {
  membershipsData: { memberships: [], count: 0 },
  membership: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MEMBERSHIPS_SEARCH:
      return { ...state, loading: true, error: '' };
    case MEMBERSHIPS_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        membershipsData: action.payload,
        error: '',
      };
    case MEMBERSHIPS_SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        membershipsData: INIT_STATE.membershipsData,
        error: action.payload.message,
      };
    case GET_MEMBERSHIP:
      return { ...state, loading: true, error: '' };
    case GET_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
        membership: action.payload,
        error: '',
      };
    case GET_MEMBERSHIP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
